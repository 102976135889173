<template>
  <div class="resume-contest-preview">
    <div class="contest-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">安徽财贸职业学院马克思主义学院 | “职来职住”简历大赛</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="contest-main">
      <el-row :gutter="24">
        <el-col :span="16">
          <div class="col-left" v-if="isPDF">
            <div v-for="item in numPagesPdf" :key="'pdf-' + item">
              <pdf v-if="resumePdf" :page="item" :src="resumePdf"></pdf>
            </div>
            <div v-for="path in numPagesPath" :key="'path-' + path">
              <pdf v-if="resumePdfData" :page="path" :src="resumePdfData"></pdf>
            </div>
          </div>
          <div class="flow-l" v-if="isPath">
            <div class="col-center">
              <div class="main-top">
                <div class="personal-data">
                  <div class="data-name">{{ main_data.personal_details.name }}</div>
                  <div class="data-position">求职意向：{{ main_data.personal_details.position }}</div>
                  <div class="grade-location">
                    <span class="data">学历：{{ main_data.personal_details.grade }}</span>
                    <span>地址：{{ main_data.personal_details.location }}</span>
                  </div>
                  <div class="phone-email">
                    <span class="data">电话：{{ main_data.personal_details.phone }}</span>
                    <span>邮箱：{{ main_data.personal_details.email }}</span>
                  </div>
                </div>
                <div class="personal-img">
                  <el-image class="img" :src="main_data.personal_details.img" fit="cover"></el-image>
                </div>
              </div>
              <div class="main-bottom">
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">教育背景</span>
                  </div>
                  <div class="education-span" v-for="item in main_data.education_data" :key="item.id">
                    <span>{{ item.college }}</span>
                    <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    <span>{{ item.specialty }}</span>
                    <span>{{ item.education }}</span>
                    <span>{{ item.institute }}</span>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">工作经历</span>
                  </div>
                  <div class="item-data" v-for="item in main_data.work_data" :key="item.id">
                    <div class="data-title">
                      <span class="title-left">{{ item.work_title }}</span>
                      <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    </div>
                    <div class="data-content">
                      <span class="content">{{ item.work_experience }}</span>
                    </div>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">项目经验</span>
                  </div>
                  <div class="item-data" v-for="item in main_data.project_data" :key="item.id">
                    <div class="data-title">
                      <span class="title-left">{{ item.project_title }}</span>
                      <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    </div>
                    <div class="data-content">
                      <span class="content">{{ item.project_experience }}</span>
                    </div>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">技能优势</span>
                  </div>
                  <div class="item-data">
                    <div class="data-content">
                      <span class="content">{{ main_data.skill_data }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="col-right">
            <div class="right-title">报名成功</div>
            <div class="right-data">待审核</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { getResume } from '../../../request/api'

export default {
  components: {
    pdf
  },
  data() {
    return {
      resume_id: '',
      main_data: {
        personal_details: {
          name: '',
          position: '',
          grade: '',
          location: '',
          phone: '',
          email: '',
          img: 'https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png',
          sex: ''
        },
        education_data: [],
        work_data: [],
        project_data: [],
        skill_data: '',
      },
      isPDF: false,
      resumePdf: null,
      numPagesPdf: null,
      resumePdfData: null,
      numPagesPath: null,
      isPath: false
    }
  },
  methods: {
    sendExit() {
      this.$emit('update-value')
    },
    async init() {
      try {
        const res = await getResume(this.resume_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        const resumeMy = res.data.data

        if (resumeMy.radio === 1) {
          this.isPath = true
          this.main_data = JSON.parse(resumeMy.resume)
        } else {
          this.isPDF = true

          const loadingTaskPdf = pdf.createLoadingTask(resumeMy.pdfImg)
          loadingTaskPdf.promise.then(pdf => {
            this.numPagesPdf = pdf.numPages

            this.resumePdf = resumeMy.pdfImg
          }).catch(error => {
            this.$message.error(error)
          })

          if (resumeMy.pathImg !== null) {
            const loadingTaskPath = pdf.createLoadingTask(resumeMy.pathImg)
            loadingTaskPath.promise.then(pdf => {
              this.numPagesPath = pdf.numPages

              this.resumePdfData = resumeMy.pathImg
            }).catch(error => {
              this.$message.error(error)
            })
          }

        }
      } catch(error) {
        this.$message.error(error);
      }
    }
  },
  created() {
    this.resume_id = this.$route.query.resume_id
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.resume-contest-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contest-main {
    height: 92%;
    padding: 4px;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
        .flow-l {
          height: 100%;
          overflow-y: auto;
          background-color: #fff;
          .col-center {
            height: 100%;
            display: flex;
            flex-direction: column;
            .main-top {
              min-height: 28%;
              display: flex;
              .personal-data {
                width: 76%;
                display: flex;
                flex-direction: column;
                padding: 30px 50px;
                box-sizing: border-box;
                .data-name {
                  font-size: 22px;
                  margin-bottom: 14px;
                  font-weight: bold;
                }
                .data-position {
                  margin-bottom: 10px;
                }
                .grade-location {
                  margin-bottom: 10px;
                  display: flex;
                  font-size: 14px;
                }
                .data {
                  width: 40%;
                }
                .phone-email {
                  display: flex;
                  font-size: 14px;
                }
              }
              .personal-img {
                flex: 1;
                display: flex;
                align-items: center;
                .img {
                  width: 140px;
                  height: 140px;
                  border-radius: 50%;
                  border: 2px solid #dfdfdf;
                }
              }
            }
            .main-bottom {
              flex: 1;
              padding: 28px;
              box-sizing: border-box;
              .main-data {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                .education-img {
                  height: 60px;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  .title {
                    width: 100px;
                    text-align: center;
                    border-left: 5px solid #2a6ee9;
                  }
                }
                .item-data {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                  .data-title {
                    display: flex;
                    font-weight: bold;
                    margin-bottom: 4px;
                    .title-left {
                      margin-right: 10px;
                    }
                  }
                  .data-content {
                    padding: 4px;
                    font-size: 14px;
                    .content {
                      white-space: pre-wrap;
                    }
                  }
                }
                .education-span {
                  display: flex;
                  font-size: 14px;
                  justify-content: space-between;
                  font-weight: bold;
                  margin-bottom: 10px;
                  height: 50px;
                  align-items: center;
                }
              }
            }
          }
        }
        .col-left {
          height: 100%;
          background-color: #fff;
          overflow-y: auto;
        }
        .col-right {
          background-color: #fff;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .right-title {
            background-color: #f29a0f;
            height: 28px;
            width: 100px;
            color: #fff;
            line-height: 28px;
            text-align: center;
            border-radius: 6px;
          }
          .right-data {
            font-size: 50px;
            color: #2a6ee9;
          }
        }
      }
    }

  }
  .contest-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>